import { useState, useEffect } from 'react';
//import { useHistory } from 'react-router-dom';
import * as jose from 'jose'
import config from '../config';

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  //const history = useHistory();

  async function effect () {
    const token = localStorage.getItem('token');
    if (token) {
      const isValid = await validateToken(token);
      if (isValid) {
        setIsAuthenticated(isValid);
        const decoded = jose.decodeJwt(token);
        setUserRoles(decoded.roles || []);
      } else {
        localStorage.removeItem('token');
      }
    }
  }


  useEffect(() => {
   effect();
  }, []);

  const validateToken = async (token) => {
    // console.log(config.publicKey);
    try {
      const keyParsed = config.publicKey.replace(/\\n/g, '\n');
      const publicKey = await jose.importSPKI(keyParsed, 'RS256');
      const decoded = await jose.jwtVerify(token, publicKey, { algorithms: ['RS256'] });
      // console.log('===validateToken===decoded', decoded);
      const currentTime = Date.now() / 1000;
      return decoded?.payload?.exp > currentTime;
    } catch (error) {
      if (error.message === '"exp" claim timestamp check failed') {
        window.location.href=`${config.prefixPath}/login?externalError=expired`;
      }
      //console.log('====msg', error.message)
      //console.log(error)
      //console.error('Invalid token', error);
      return false;
    }
  };

  const login = async (token) => {
    const valid = await validateToken(token);
    //console.log('valid', valid);
    if (valid) {
      localStorage.setItem('token', token);
      setIsAuthenticated(true);
      const decoded = jose.decodeJwt(token);
      setUserRoles(decoded.roles || []);
      //console.log('Valid token');
      return true;
    } else {
      return false;
      //console.error('Invalid tokennnnn');
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUserRoles([]);
  };

  return {
    isAuthenticated,
    userRoles,
    login,
    logout,
  };
};

export default useAuth;
