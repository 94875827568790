import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useAuthContext } from '../contexts/AuthContext';
import Page404 from './404';
import api from "../lib/request.js";
import config from "../config.js";
import { Navigate } from 'react-router-dom';
import Footer from '../components/Footer';
import HeaderNavbar from '../components/HeaderNavbar';
import moment from 'moment';
import toast, { Toaster } from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'
import { faSortUp } from '@fortawesome/free-solid-svg-icons'
import { faCodePullRequest } from '@fortawesome/free-solid-svg-icons'
import EditableTextArea from '../components/EditableTextArea';
import {Modal, Container} from 'react-bootstrap';

function OrdersPage() {
  const { isAuthenticated, userRoles } = useAuthContext();
  let { project, guide } = useParams();

  const [statusCode, setStatusCode] = useState(null);
  const [data, setData] = useState(null);
  const [remeras, setRemeras] = useState(null);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({complete: false});
  const [sortOrder, setSortOrder] = useState(true);
  const [sortBy, setSortBy] = useState('id');
  const [filterText, setFilterText] = useState('');
  const [colapsadas, setColapsadas] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [devolverStock, setDevolverStock] = useState(true);
  const [borrarCompletamete, setBorrarCompletamete] = useState(false);
  const [toCancel, setToCancel] = useState(null);
  const [toCancelName, setToCancelName] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [showDelivered, setShowDelivered] = useState(false);


  useEffect(() => {
    if (isLoaded) {
      // Scroll to the anchor once data is loaded
      const hash = window.location.hash.substring(1);
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
          removeHash();
        }
      }
    }
  }, [isLoaded]);

  async function fetchData() {
    try {
      setLoading(true);
      const response = await api.get(`/api/v1/orders`);
      setLoading(false);
      setData(response);
      sortData(response, 'id', false);
      setStatusCode(null);
      setIsLoaded(true);
    } catch (e) {
      setLoading(false);
      console.log(e);
      if(e.statusCode === 404){
        setStatusCode('404');
      }
      setData(null);
    }
  }

  async function fetchRemeras() {
    try {
      const response = await api.get(`/api/v1/remeras`);
      setRemeras(response);
    } catch (e) {
      console.log(e);
      setRemeras(null);
    }
  }

  function sortData (data, field, dir) {
    if (data && data.sort) {
      setSortBy(field);
      setSortOrder(!sortOrder);
      data.sort((a, b) => {
        if (a[field] < b[field]) return dir ? -1 : 1;
        if (a[field] > b[field]) return dir ? 1 : -1;
        return 0;
      });
      setData([...data]);
    }
  }

  function changeStateRequest(id, state, text) {
    if (window.confirm(`Queres cambiar el estado de la orden a ${text}?`)) {
      const requestData = {
        method: "put",
        url: `/api/v1/orders/${id}/state`,
        data: {'state': state}
      };
      setLoading(true);
      api(requestData).then(res => {
        setLoading(false);
        if (res.ok) {
          // ok!          
          setData(prevData => {
            prevData = prevData.map(d => {
              if (d.id === id) {
                d.state = state;
              }
              return d;
            })
            return prevData;
          });
          toast.success("Orden actualizada con exito!")
        } else if (res.error) {
          alert(res.message);
          toast.error(res.message)
        } else {
          toast.error("Error actualizando estado1");
        }
      }).catch (e => {
        setLoading(false);
        console.log(e);
        toast.error("Error actualizando estado2");
      });
    }
  }

  function changeNotesRequest(id, newValue) {
      const requestData = {
        method: "put",
        url: `/api/v1/orders/${id}/notes`,
        data: {'note': newValue}
      };
      setLoading(true);
      api(requestData).then(res => {
        setLoading(false);
        if (res.ok) {
          // ok!
          setData(prevData => {
            prevData = prevData.map(d => {
              if (d.id === parseInt(id)) {
                d.notas = newValue;
              }
              return d;
            })
            return prevData;
          });
          toast.success("Orden actualizada con exito!");
        } else if (res.error) {
          alert(res.message);
          toast.error(res.message)
        } else {
          toast.error("Error actualizando nota");
        }
      }).catch (e => {
        setLoading(false);
        console.log(e);
        toast.error("Error actualizando nota");
      });
  }

  function removeHash() {
    // Accedemos al historial del navegador para manipular la URL
    const { history } = window;
    const { location } = window;

    // Si el hash está presente en la URL, lo eliminamos
    if (location.hash) {
        history.replaceState(
            // Puedes pasar cualquier dato de estado que necesites aquí, null en este caso
            null,
            // El título de la página, que ignora la mayoría de los navegadores en esto
            '',
            // La nueva URL sin el ancla
            location.pathname + location.search  // conservamos el pathname y query params
        );
    }
}

  useEffect(() => {
    fetchData();
    fetchRemeras();
  }, []);

  function getGoBack() {
    return window.encodeURI(`/admin/ordenes`);
  }

  function getOrderState(state) {
    switch(state) {
      case 'created':
        return {'label': 'Creada', class: 'btn-danger'};
      case 'paid':
        return {'label': 'Pagada', class: 'btn-primary'};
      case 'ready':
        return {'label': 'Lista', class: 'btn-info'};
      case 'delivered':
        return {'label': 'Entregada', class: 'btn-success'};
      break;
      case 'partial_delivered':
        return {'label': 'Entregada Parcialmente', class: 'btn-success'};
      break;
      case 'canceled':
        return {'label': 'Cancelada', class: 'btn-secondary'};
      break;
      default:
        return {'label': 'unknown', class: 'btn-danger'};
      break;
    }
  }

  function setCreada(order) {
    changeStateRequest(order.id, 'created', 'Creada');
  }

  function setPagada(order) {
    changeStateRequest(order.id, 'paid', 'Pagada');
  }

  function setReady(order) {
    changeStateRequest(order.id, 'ready', 'Lista para retirar');
  }

  function setDelivered(order) {
    changeStateRequest(order.id, 'delivered', 'Entregada');
  }

  function setPartialDelivered(order) {
    changeStateRequest(order.id, 'partial_delivered', 'Entregada Parcialmente');
  }

  function getDateColor(date, state) {
    const stateData = getOrderState(state);
    if (state === 'created') {
      const diffSeg = (new Date().getTime() - new Date(date).getTime()) / 1000;
      if (diffSeg > (60*60*24*7)) {
        return 'danger';
      } else if (diffSeg > (60*60*24)) {
        return 'warning';
      } else {
        return 'success';
      }
    } else {
      return stateData.class.replace('btn-', '');
    }
  }

  function SortIndicator({me}) {
    if (me === sortBy) {
      return (sortOrder ? <FontAwesomeIcon icon={faSortUp}/> : <FontAwesomeIcon icon={faSortDown}/>)
    }
    return null;
  }

  function filterData(myData) {
    if (filterText) {
      const newData = myData.filter((d) => {
        return (`p${d.id.toString().toLowerCase()}`.indexOf(filterText) > -1) || (d.id.toString().toLowerCase().indexOf(filterText) > -1) || (d.nombre.toLowerCase().indexOf(filterText) > -1) || (d.email.toLowerCase().indexOf(filterText) > -1) || (d.telefono.toLowerCase().indexOf(filterText) > -1) || (translateState(d.state).toLowerCase().indexOf(filterText) > -1)
      }).filter(d => {
        if (showDelivered) {
          return true;
        } else {
          if (d.state === 'delivered') {
            return false;
          } else {
            return true;
          }
        }
      });
      return newData;
    } else {
      return myData.filter(d => {
        if (showDelivered) {
          return true;
        } else {
          if (d.state === 'delivered') {
            return false;
          } else {
            return true;
          }
        }
      });
    }
  }

  function translateState (state) {
    const translation = {
      'created': "creada",
      'paid': "pagada",
      'ready': "lista para retirar",
      'delivered': "entregada",
      'partial_delivered': "entregada parcialmente",
      'canceled': "cancelada",
    }
    return translation[state] || 'foo';
  }

  function filterList(value) {
    setFilterText(value.trim().toLowerCase());
  }

  function DrawBackOrder ({item}) {
    return (
      item.cantidadBackorder > 0 ?
    <div style={{display: 'flex'}}><small style={{marginLeft: 19, fontStyle: 'italic'}}>Tenemos {item.cantidadEntregaInmediata} para entrega inmediata y {item.cantidadBackorder} para entrega a partir del {item.fechaBackOrder}</small></div>
    : null);
  }

  function getItemsByModel (items) {
    const stockModels = [];
    const backorderModels = [];
    items.map(i => {
      if (stockModels.filter(s => s.id === i.id ).length) {

      } else {

      }
    });

    return {
      stockModels,
      backorderModels
    }
  }

  function checkStock(productId, talle) {
    if (!remeras) return {
      stock: 0
    }

    const remera = remeras.filter(r => r.id === productId)[0] || null;
    if (remera && remera.talles) {
      if (talle in remera.talles) {
        return {
          stock: remera.talles[talle]
        }
      }
    } else {
      return {
        stock: 0
      }
    }
  }

  function drawPullBackStock (item, order) {
    if (order.backorderVoid) return null;
    if (order.stockVoid) return null;
    const currentStock = checkStock(item.id, item.talle);
    if (currentStock.stock <= 0) {
      return null;
    }
    let pullBack = 0;
    if (currentStock.stock >= item.cantidadBackorder) {
      pullBack = item.cantidadBackorder;
    } else {
      pullBack = currentStock.stock;
    }
    if (pullBack <= 0) {
      return null;
    }
    return (
      <span onClick={(e) => tryPullBack(item, order, pullBack)} title="Ahora hay stock de este item. Moverlo?" style={{cursor: 'pointer'}} className={`badge text-bg-${pullBack !==item.cantidadBackorder ? 'warning': 'success'}`}><FontAwesomeIcon icon={faCodePullRequest}/> ({pullBack})</span>
    )
  }

  function tryPullBack (item, order, pullBack) {
    if (window.confirm(`Estas a punto de mover ${pullBack} unidade(s) de este talle que estaban encargadas (habia ${item.cantidadBackorder} encargada(s)) a la orden para entrega inmediata. Confirmar? `)) {
      const requestData = {
        method: "put",
        url: `/api/v1/orders/${order.id}/putback`,
        data: {
          productId: item.id,
          talle: item.talle,
          count: pullBack
        }
      };
      setLoading(true);
      api(requestData).then(res => {
        setLoading(false);
        if (res.ok) {
          // ok!
          removeHash();
          fetchRemeras();
          fetchData();
          toast.success("Orden actualizada con exito! Acordate de avisarle por mail del cambio!");
        } else if (res.error) {
          alert(res.message);
          toast.error(res.message)
        } else {
          toast.error("Error actualizando orden");
        }
      }).catch (e => {
        setLoading(false);
        console.log(e);
        toast.error("Error actualizando orden");
      });
    }
  }
  
  function DrawItemsBack ({order}) {
    return (
      <>
      <div>{order.backOrderItems > 0 ? `Encargadas:` : null}</div>
      {order.items.map((item, index) => {
        return (item.cantidadBackorder > 0 ? <><div className={order.backorderVoid ? 'tachado':null} key={`i${index}`}><strong>{item.cantidadBackorder}x</strong> Modelo <strong>{item.nombre}</strong> en talle <strong>{item.talle}</strong> para el {item.fechaBackOrder}. {drawPullBackStock(item, order)}</div></> : '')

      })}
      <div className={order.backorderVoid ? 'tachado':null}><strong>{order.backOrderItems ? `${order.backOrderItems} total encargadas`: null}</strong></div>
      </>
    )
  }

  function DrawItems ({order}) {
    return (
      <>
      <div>{order.stockItems > 0 ? `Entrega Inmediata:` : null}</div>
      {order.items.map((item, index) => {
        return (item.cantidadEntregaInmediata > 0 ? <div className={order.stockVoid ? 'tachado':null} key={`i${index}`}><strong>{item.cantidadEntregaInmediata}x</strong> Modelo <strong>{item.nombre}</strong> en talle <strong>{item.talle}</strong>{item.pullBack ? <small> ({item.pullBack} pullback)</small> : null}</div> : '')
      })}
      <div className={order.stockVoid ? 'tachado':null}><strong>{order.stockItems ? `${order.stockItems} total entrega inmediata`: null}</strong></div>
      </>
    )
  }

  function updateNotes(path, newValue) {
    console.log('path', path)
    console.log('newValue', newValue);
    const parts = path.split('.');
    changeNotesRequest(parseInt(parts[0]), newValue);
  }

  function DrawNotas({order}) {
    return (
      <>
       <div>Notas:</div>
       <div><EditableTextArea
        editable={true}
        name='notas'
        contentStyle= {{border: '0px solid #c3c3c3', borderRadius: 1, margin: 0, padding: 0}}
        editStyle={{width: '100%', height:100}}
        value={order.notas}
        index={0}
        onValueChange={updateNotes}
        path={`${order.id}.notas`}/></div>
      </>
      )
  }

  function toggleColapse(e, orderId) {
    if (colapsadas.indexOf(orderId) > -1) {
      // sacar
      setColapsadas(prevData => {
        prevData = prevData.filter(e => e !== orderId);
        return [...prevData]
      })
    } else {
      setColapsadas(prevData => {return [...prevData, orderId]})
    }
  }

  function uncolapseAll () {
    setColapsadas([]);
  }

  function tryCancel(order) {
    setModalShow(true);
    setToCancel(order);
    setToCancelName(order.nombre);
  }

  function colapseAll () {
    const ids = data.map(d => d.id);
    setColapsadas(ids);
  }

  function cancelOrder () {
    setModalShow(false);
    if (!toCancel) return;
    const requestData = {
      method: "put",
      url: `/api/v1/orders/${toCancel.id}/cancel`,
      data: {
        returnStock: toCancel.stockVoid ? false : devolverStock,
        delete: borrarCompletamete
      }
    };
    setLoading(true);
    api(requestData).then(res => {
      setDevolverStock(true); setBorrarCompletamete(false); setToCancel(null); setToCancelName('')
      setLoading(false);
      if (res.ok) {
        // ok!
        removeHash();
        fetchRemeras();
        fetchData();
        toast.success("Orden actualizada con exito!");
      } else if (res.error) {
        alert(res.message);
        toast.error(res.message)
      } else {
        toast.error("Error actualizando orden");
      }
    }).catch (e => {
      setDevolverStock(true); setBorrarCompletamete(false); setToCancel(null); setToCancelName('')
      setLoading(false);
      console.log(e);
      toast.error("Error actualizando orden");
    });
  }

  function CenteredModal(props) {
    return (
      <Modal
        {...props}
        size="xs"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {toCancel ? toCancel.stockVoid ? 'Borrar': 'Cancelar' : ''} Orden?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
            <div className="row">
              <div className="col-12" >
              <div>Seguro que queres {toCancel ? toCancel.stockVoid ? 'borrar': 'cancelar' : ''} la orden <strong>#{toCancel ? toCancel.id : ''}</strong> de <strong>{toCancelName}</strong>?</div>
              {toCancel && toCancel.stockVoid ? null : <div>
              <input style={{marginTop: 15}} checked={devolverStock} onChange={e => setDevolverStock(e.target.checked)} type="checkbox"/>
                 <label onClick={e => setDevolverStock(!devolverStock)}  style={{marginLeft: 5}}>Devolver stock y descontar encargadas</label>
              </div>}
               <div>
              <input style={{marginTop: 15}} checked={borrarCompletamete} onChange={e => setBorrarCompletamete(e.target.checked)} type="checkbox"/>
                 <label onClick={e => setBorrarCompletamete(!borrarCompletamete)}  style={{marginLeft: 5}}>Borrar completamente de la base de datos</label>
              </div>
              </div>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-dark" onClick={props.onHide}>Cancelar</button>
          <button className="btn btn-sm btn-outline-primary" onClick={props.onSubmit}>Confirmar</button>
        </Modal.Footer>
      </Modal>
    );
  }

  if (statusCode === '404') {
    return (<Page404/>);
  }

  if (!data || !remeras) return;


  if (!isAuthenticated) return (<Navigate to={`${config.prefixPath}/login?goback=${getGoBack()}`}/>)

  return (isAuthenticated && userRoles.includes('admin') &&
    <>
    <CenteredModal show={modalShow} onSubmit={cancelOrder} onHide={() => {setModalShow(false); setDevolverStock(true); setBorrarCompletamete(false); setToCancel(null); setToCancelName('')}}/>
    <HeaderNavbar/>
     <ol className="breadcrumb navi">
      <li className="breadcrumb-item"><a href={`${config.prefixPath}/admin`}>Admin</a></li>
      <li className="breadcrumb-item active" aria-current="page">Ordenes</li>
    </ol>
    <h1>Ordenes</h1>
   
     <div className="row">
      <div className="col-12">
      <div>
      {data.length} ordenes. {data.reduce((accumulator, currentObject) => accumulator + currentObject.stockItems, 0)} vendidas en stock. {data.reduce((accumulator, currentObject) => accumulator + currentObject.backOrderItems, 0)} encargadas. ${Intl.NumberFormat("de-DE").format(data.reduce((accumulator, currentObject) => accumulator + currentObject.precioTotal, 0))} recaudado (${Intl.NumberFormat("de-DE").format(data.reduce((accumulator, currentObject) => accumulator + ((currentObject.state === 'paid' || currentObject.state === 'ready' || currentObject.state === 'delivered' || currentObject.state === 'partial_delivered') ? currentObject.precioTotal:0), 0))} ya pagado). {data.filter(d => d.state === 'delivered').length} ordenes entregadas.</div>
      <div style={{float: 'right'}}>Buscar: <input type="text" onChange={(e) => filterList(e.target.value)}/><br/>Mostrando: {filterData(data).length} ordenes.<br/> <div>
      <input style={{marginTop: 15}} checked={showDelivered} onChange={e => setShowDelivered(!showDelivered)} type="checkbox"/>
      <label onClick={e => setShowDelivered(!showDelivered)}  style={{marginLeft: 5}}>Mostrar Entregadas</label>
      </div></div>
      <a href="#" onClick={uncolapseAll}>Descolapsar</a> | <a href="#" onClick={colapseAll}>Colapsar</a>
     
      <table className="table table-striped">
        <thead>
          <tr>
            <th style={{cursor:'pointer'}} onClick={(e)=>sortData(data, 'id', !sortOrder)} scope="col">#<SortIndicator me="id"/></th>
            <th style={{cursor:'pointer'}} onClick={(e)=>sortData(data, 'nombre', !sortOrder)} scope="col">Nombre<SortIndicator me="nombre"/></th>
            <th style={{cursor:'pointer'}} onClick={(e)=>sortData(data, 'email', !sortOrder)} scope="col">Email<SortIndicator me="email"/></th>
            <th style={{cursor:'pointer'}} onClick={(e)=>sortData(data, 'telefono', !sortOrder)} scope="col">Telefono<SortIndicator me="telefono"/></th>
            <th style={{cursor:'pointer'}} onClick={(e)=>sortData(data, 'suscripcion', !sortOrder)} scope="col">Suscribir<SortIndicator me="suscripcion"/></th>
            <th style={{cursor:'pointer'}} onClick={(e)=>sortData(data, 'createdDate', !sortOrder)} scope="col">Creacion<SortIndicator me="createdDate"/></th>
            <th style={{cursor:'pointer'}} onClick={(e)=>sortData(data, 'stockItems', !sortOrder)} scope="col">En Stock<SortIndicator me="stockItems"/></th>
            <th style={{cursor:'pointer'}} onClick={(e)=>sortData(data, 'backOrderItems', !sortOrder)} scope="col">Encargadas<SortIndicator me="backOrderItems"/></th>
            <th style={{cursor:'pointer'}} onClick={(e)=>sortData(data, 'totalItems', !sortOrder)} scope="col">Items<SortIndicator me="totalItems"/></th>
            <th style={{cursor:'pointer'}} onClick={(e)=>sortData(data, 'precioTotal', !sortOrder)} scope="col">Total<SortIndicator me="precioTotal"/></th>
            <th style={{cursor:'pointer'}} onClick={(e)=>sortData(data, 'state', !sortOrder)} scope="col">Estado<SortIndicator me="state"/></th>
          </tr>
        </thead>
        <tbody className="table-group-divider">
        {filterData(data).map((order, index) => {
          return (
            <>
             <tr id={`orden${order.id}`} key={index}>
              <th scope="row"><a target="_blank" href={`${config.prefixPath}/mi-orden/${order.slug}`}>{order.id}</a></th>
              <td style={{cursor: 'pointer'}} onClick={(e) => toggleColapse(e, order.id)}>{order.nombre}</td>
              <td>{order.email}</td>
              <td>{order.telefono}</td>
              <td>{order.suscripcion ? "Si" : "No"}</td>
              <td>{moment(order.createdDate).format('DD/MM/YY HH:mm:ss')}<br/><span className={`badge text-bg-${getDateColor(order.createdDate, order.state)}`}>{moment(order.createdDate).fromNow()}</span></td>
              <td className={order.stockVoid ? 'tachado':null}>{order.stockItems}</td>
              <td className={order.backorderVoid ? 'tachado':null}>{order.backOrderItems}</td>
              <td className={order.state === 'canceled' ? 'tachado':null}>{order.totalItems}</td>
              <td className={order.backorderVoid ? 'tachado':null}>${Intl.NumberFormat("de-DE").format(order.precioTotal)}</td>
              <td>
                {loading ? <Spinner size="sm" animation="border" /> : <div className="btn-group" role="group">
                  <button type="button" className={`btn ${getOrderState(order.state).class} dropdown-toggle`} data-bs-toggle="dropdown" aria-expanded="false">
                    {getOrderState(order.state).label}
                  </button>
                  <ul className="dropdown-menu">
                    
                    {order.state === 'canceled' ? null :
                    <>
                    <li><span style={{cursor: 'pointer'}} className="dropdown-item" onClick={(e) => setCreada(order)}>Creada</span></li>
                    <li><span style={{cursor: 'pointer'}} className="dropdown-item" onClick={(e) => setPagada(order)}>Pagada</span></li>
                    <li><span style={{cursor: 'pointer'}} className="dropdown-item" onClick={(e) => setReady(order)}>Lista</span></li>
                    <li><span style={{cursor: 'pointer'}} className="dropdown-item" onClick={(e) => setPartialDelivered(order)}>Entregada Parcialmente</span></li>
                    <li><span style={{cursor: 'pointer'}} className="dropdown-item" onClick={(e) => setDelivered(order)}>Entregada</span></li>
                    </>
                    }  
                    <li><span style={{cursor: 'pointer'}} className="text-danger dropdown-item" onClick={(e) => tryCancel(order)}>{order.state === 'canceled' ? `${order.stockVoid ? `Borrar` : `Mover Stock / Borrar`}` : 'Cancelar'}</span></li>
                  </ul>
                </div>}
              </td>
            </tr>
            {colapsadas.indexOf(order.id) > -1 ? null : <tr style={{borderBottom: `1px solid black`}} key={`t${index}`}>
              <td></td>
              <td colSpan="3"><DrawItems order={order}/></td>
              <td colSpan="5"><DrawItemsBack order={order}/></td>
              <td colSpan="2"><DrawNotas order={order}/></td>
            </tr>}
            </>
          )
        })}

        </tbody>
      </table>

      </div>
    </div>
    <Toaster position="bottom-left" toastOptions={{duration: 5000, style: {background: '#e3e3e3'}}}/>
    <Footer/>
    </>
  );
}

export default OrdersPage;