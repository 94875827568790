import React from 'react';
import { Navigate } from 'react-router-dom';
import HeaderNavbar from '../components/HeaderNavbar';
import Footer from '../components/Footer';

import config from '../config';

function HomePage() {
  return (
    <>
    <HeaderNavbar/>
    
    <div className="container banner-container">
    <img onClick={(e) => {document.location = '/lenguitas/remeras';}} class="banner-image" src="/uploads/home.jpeg" alt="Banner Image"/>
    </div>
    <div class="container">
    <div style={{fontSize: '1.2em', marginTop: 30}}>Hace <a href={"/lenguitas/remeras"}>click aqui</a> para comprar la tuya!
    <br/><br/>Lo recaudado será utilizado para la <strong>puesta en valor de los baños.</strong></div>
    </div>


    </>
  );
}

export default HomePage;