import React, { useState, useEffect } from "react";
import toast, { Toaster } from 'react-hot-toast';
import {Modal, Container} from 'react-bootstrap';
import HeaderNavbar from '../components/HeaderNavbar';
import Footer from '../components/Footer';
import Spinner from 'react-bootstrap/Spinner';
import config from "../config";
import api from "../lib/request.js";
import { useAuthContext } from '../contexts/AuthContext';

function ProjectsList() {
  const { isAuthenticated, userRoles } = useAuthContext();



  return (
    <>

     <HeaderNavbar/>
      <h1 style={{display: 'block'}}>La Remera de la Coope 2024!</h1>
      <div style={{display: 'block'}} >Colabora con un bono contribución de <strong>$10.100</strong> y llevate una remera por cada bono.</div>
      <div>Las remeras son de manga corta unisex de algodón peinado 24/1 y estampado a 3 colores con serigrafía.</div>
      <div><strong><i>Lo recaudado sera utilizado para la puesta en valor de los baños.</i></strong></div>
      <span style={{display: 'block'}}></span>
      <div style={{height: 30}}></div>
      <div className="row">
        <div className="col-12">
        <h2 style={{display: 'block'}} >Dudas, consultas, sugerencias?</h2>
        <div>Escribinos a <a href = "mailto:laremeradelacoope@gmail.com">laremeradelacoope@gmail.com</a></div>

        </div>
      </div>

      <Toaster position="bottom-left" toastOptions={{duration: 5000, style: {background: '#e3e3e3'}}}/>
      <Footer/>
    </>
  );
}

export default ProjectsList;