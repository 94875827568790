import React, { useState, useEffect } from 'react';

function EditableInput ({ name, value, index, onValueChange, path, contentStyle, editStyle, editable, number}) {
    
    const [edit, setEdit] = useState(false);
    const [text, setText] = useState(value);

    useEffect(() => {
      if (edit) {
        document.getElementById(`${name}-${index}`).focus();
        document.getElementById(`${name}-${index}`).select();
      }
    }, [edit, name, index]);

    const handleContentClick = (index, content) => {
      if (!editable) return;
      setEdit(true);
      if (number && safeParseInt(content) === null) {
        return;
      }
      setText(content);
    };

    function handleChange(event) {
      if (number && safeParseInt(event.target.value) === null && event.target.value !== '') {
        return;
      }
      setText(event.target.value);
    }

    function safeParseInt(input) {
      const number = Number(input);
      if (isNaN(number) || !/^\d+$/.test(input)) {
          return null;
      } else {
          return number;
      }
    }

    const handleEditableBlur = (event, index) => {
      setEdit(false);
      if (value.toString().trim() !== event.target.value.trim()) {
        if (number && safeParseInt(event.target.value.trim()) === null) {
          return;
        }
          if (onValueChange) onValueChange(path, event.target.value);
          setText(event.target.value);
      }
      
    };

    const handleKeyPress = (event, index) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleEditableBlur(event, index);
      }
    }

    return (
      ((edit && editable) ? (
        <input 
          id={`${name}-${index}`}
          key={`${name}-${index}`}
          type="text" 
          value={text} 
          onChange={handleChange} 
          onBlur={(e) => handleEditableBlur(e, index)}
          onKeyPress={(e) => handleKeyPress(e, index)}
          style={{...editStyle}}
        />
      ) : (
        <div style={{cursor: editable ? "context-menu" : ""}} id={`editable-${name}-${index}`} onClick={() => handleContentClick(index, text)}>{text}</div>
      ))
    );
}

export default EditableInput;