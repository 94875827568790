import React from 'react';
import {Container, NavDropdown} from 'react-bootstrap';
import config from '../config';
import { useAuthContext } from '../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSchool } from '@fortawesome/free-solid-svg-icons'

function HeaderNavbar({extraContent, extraUserContent}) {
  const { isAuthenticated, userRoles, logout } = useAuthContext();

  function goToLogout () {
    logout();
  }

  return (
    <nav className="navbar sticky-top navbar-light bg-light justify-content-between">
      <Container>
        <a className="navbar-brand" href={`${config.prefixPath}`} >
        <FontAwesomeIcon style={{marginRight: 10}} width="30" height="26"  className="d-inline-block align-text-top" icon={faSchool}/>
        <span>Cooperadora Lengüitas</span>
        </a>
        {(extraContent && isAuthenticated && userRoles.includes('admin')) ? extraContent : ''}
        {isAuthenticated ? <div>
          {extraUserContent ? extraUserContent : null} 
          <NavDropdown style={{display: 'inline', float: 'right'}} title="Admin Menu" id="basic-nav-dropdown">
            <NavDropdown.Item href={`${config.prefixPath}/admin/ordenes`}>Ordenes</NavDropdown.Item>
            <NavDropdown.Item href={`${config.prefixPath}/admin/reposicion`}>Reposición</NavDropdown.Item>
            <NavDropdown.Item href={`${config.prefixPath}/admin/stock`}>Stock</NavDropdown.Item>
            <NavDropdown.Item className={'text-danger'} onClick={goToLogout}>Logout</NavDropdown.Item>
          </NavDropdown>
          </div>
         :
        <a href={`${config.prefixPath}/ayuda`}>Ayuda/FAQ</a>
        }
      </Container>
    </nav>
  );
}

export default HeaderNavbar;