import React, { useState, useEffect } from "react";
import toast, { Toaster } from 'react-hot-toast';
import {Modal, Container} from 'react-bootstrap';
import HeaderNavbar from '../components/HeaderNavbar';
import Footer from '../components/Footer';
import Spinner from 'react-bootstrap/Spinner';
import config from "../config";
import api from "../lib/request.js";
import { useAuthContext } from '../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-solid-svg-icons'
import { useParams } from 'react-router-dom';
import Page404 from './404';

function MiOrden() {
  let { orderSlug } = useParams();
  const [error, setError] = useState(null);
  const { isAuthenticated, userRoles } = useAuthContext();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payment, setPayment] = useState(false);

  async function fetchData() {
    try {
      const response = await api.get(`/api/v1/mi-orden/${orderSlug}`);
      if (response.error) {
        setError('404');
        setData(null);
      } else {
        setData(response);
      }
    } catch (e) {
      console.log('e===', e);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  /*
  async function handleSubmit(event) {
    // Prevent form being submitted through HTML
    event.preventDefault();
    setLoading(true);

    if (!nombre.trim()) {
    alert('Ingresa tu nombre y apellido!')
    setLoading(false);
    return;
    }

    if (!email.trim()) {
      alert('Ingresa tu direccion de email!')
      setLoading(false);
      return;
    }

    if (!validateEmail(email.trim())) {
      alert('Ingresa una direccion de email valida!')
      setLoading(false);
      return;
    }

    const myOrder = parseOrder(orden, data);
    console.log(myOrder);

    const requestData = {
      method: 'post',
      url: '/api/v1/checkout',
      data: {
        "nombre": nombre.trim(),
        "email": email.trim(),
        "telefono": telefono.trim(),
        "suscripcion": suscripcion,
        "orden": myOrder
      }
    };

    try {
     const response = await api(requestData);
     // Update the state and close the modal
     setLoading(false);
     if (response.ok === true) {
       // Fetch data again
       document.getElementById('theform').reset();
       toast.success(response.message);
       document.location = `${config.prefixPath}/mi-orden/${response.order.slug}`;
     } else {
       if (response.error) {
         toast.error(response.message);
       }
     }
    } catch (error) {
      setLoading(false);
      toast.error(error.toString());
    }
  }
  */

  async function copyPrev(text, feedback, successText, errorMsg) {
    try {
        await navigator.clipboard.writeText(text);
        document.getElementById(feedback).innerText = successText;
    } catch (err) {
        alert(errorMsg);
    }
    return false;
  }

  if (error === '404') return (<Page404/>);
  if (!data) return null;

  function backOrderText(inStock, backOrder, fechaBackOrder) {
    if (inStock === 0) {
      return `En este talle y modelo tenemos para entrega a partir del ${fechaBackOrder}`;
    } else {
      return `En este talle y modelo tenemos ${inStock} para entrega inmediata y el resto (${backOrder}) de este talle y modelo para entrega a partir del ${fechaBackOrder}`;
    }
    // Tenemos {count-backOrder} para entrega inmediata y {backOrder} para entrega a partir del {config.fechaBackOrder}
  }

  function DrawBackOrder ({item}) {
    return (
      item.cantidadBackorder > 0 ?
    <div style={{display: 'flex'}}><small style={{marginLeft: 19, fontStyle: 'italic'}}>{backOrderText(item.cantidadEntregaInmediata, item.cantidadBackorder, item.fechaBackOrder)}</small></div>
    : (item.cantidadEntregaInmediata > 0 ? (<div style={{display: 'flex'}}><small style={{marginLeft: 19, fontStyle: 'italic'}}>En este talle y modelo tenemos para entrega inmediata.</small></div>) : null));
  }

  function showPayments () {
    setPayment(!payment);
    return false;
  }

  async function share () {
    if (navigator && navigator.share) {
      const shareData = {
        title: "Colabora con la compra de una remera de la Coope!",
        text: "Lo recaudado sera utilizado para la puesta en valor de los baños.",
        url: "https://remeras.cooperadoralenguitas.com.ar/",
      };

      await navigator.share(shareData);

    } else {
      copyPrev('https://remeras.cooperadoralenguitas.com.ar/', 'sharetext', 'El link fue copiado a tu portapapeles, pegalo donde quieras para compartirlo. Gracias!', 'Hubo un problema copiando la URL. Por favor difundi la web compartiendo la URL principal.')

    }

    return false;
  }

  return (
    <>
     <HeaderNavbar/>
      <h1 style={{display: 'block'}}>¡Gracias por tu aporte!</h1>
      <h3>Tu pedido está confirmado.</h3>
      <div>Te enviamos toda esta informacion por email, chequea correo no deseado. Tambien vas a poder ver la evolucion de tu orden en esta misma página.</div><br/>
      <div className="row">
        <div className="col-12">
          <div className="card-body card mb-3 text-decoration-none bg-body-tertiary" key={`detalle`}>
           <>
           <h4>Pedido #{data.id}</h4>
            {data.items.map((item, index) => {
              return <div><strong>{item.cantidadTotal}x</strong> Modelo <strong>{item.nombre}</strong> en talle <strong>{item.talle}</strong><DrawBackOrder item={item}/></div>
            })}
            <br/>
            <div style={{fontSize: '1.5em'}}><strong>{data.totalItems}</strong> bono{data.totalItems === 1 ? '' :'s'} por un total de <strong>${Intl.NumberFormat("de-DE").format(data.precioTotal)}.-</strong></div>
          </>
          </div>
        </div>

        {data.state === 'paid' ?
        <div className="col-12">
          <h3 style={{display: 'block'}} >Ya recibimos tu pago</h3>
          <div style={{marginBottom: 22}}>Ya recibimos tu pago y estamos preparando tu pedido. Te vamos a mandar un email cuando este listo para retirar por la Coope. Gracias! <br/>Hace <a href="#" onClick={showPayments}>click aqui</a> si igual queres ver las formas de pago.</div>
        </div> :null}

        {data.state === 'ready' ?
        <div className="col-12">
          <h3 style={{display: 'block'}} >Tu orden esta lista para retirar!</h3>
          <div style={{marginBottom: 22}}>Tu orden ya esta lista para retirar! Podes pasar de Lunes a Viernes de 9:30 a 17:30 hs. por el edificio de primaria y secundaria en el 1er subsuelo oficina Cooperadora. Te esperamos!<br/>Hace <a href="#" onClick={showPayments}>click aqui</a> si igual queres ver las formas de pago.</div>
        </div> :null}

        {data.state === 'delivered' ?
        <div className="col-12">
          <h3 style={{display: 'block'}} >Tu orden fue entregada!</h3>
          <div style={{marginBottom: 22}}>Tu orden fue entregada. Muchas garacias por tu aporte! Nos vemos la proxima =)<br/>Hace <a href="#" onClick={showPayments}>click aqui</a> si igual queres ver las formas de pago.</div>
        </div> :null}

        {data.state === 'partial_delivered' ?
        <div className="col-12">
          <h3 style={{display: 'block'}} >Tu orden fue entregada parcialmente</h3>
          <div style={{marginBottom: 22}}>Tu orden fue entregada parcialmente. Te debemos los items que no teniamos en stock. Vas a recibir un email cuando vuelvan a entrar y esten listos para ser retirados. Nos vemos pronto! =)<br/>Hace <a href="#" onClick={showPayments}>click aqui</a> si igual queres ver las formas de pago.</div>
        </div> :null}

        {data.state === 'canceled' ?
        <div className="col-12">
          <h3 style={{display: 'block'}} >Tu orden fue cancelada</h3>
          <div style={{marginBottom: 22}}>Tu orden fue cancelada. Si aun queres comprar por favor hace un nuevo pedido haciendo <a href="/lenguitas/remeras">click aqui</a>.</div>
        </div> :null}

        {payment || data.state === 'created' ? 
        <div className="col-12">
          <h3 style={{display: 'block'}} >¿Cómo pagar?</h3>
          <span>Podes hacer una transferencia a nuestro <strong>Alias</strong> <small style={{fontStyle: 'italic'}}>(preferimos este método)</small> o directo con <strong>Mercado Pago</strong>. En ambos casos agrega <strong>P{data.id}</strong> en el campo "referencia" asi encontramos más facil tu pago.</span>
          <div style={{marginBottom: 22}}>Avisanos por email a <a href = "mailto:laremeradelacoope@gmail.com">laremeradelacoope@gmail.com</a> cuando realices el pago asi separamos tu orden! No te olvides de adjuntar el comprobante!</div>
          <div class="row align-items-md-stretch">
            <div class="col-md-6">
              <div class="h-100 p-4 bg-body-tertiary border rounded-3">
                <h4>Transferencia Bancaria</h4>
                <p><FontAwesomeIcon icon={faStar}/> Preferimos este método, nos ayudas aún más =)</p>
                <p>Banco Ciudad</p>
                <p>Caja de Ahorro en Pesos Nº 00085041</p>
                <p>CBU: 0290025410000000850411</p>
                <p><strong>Alias: Coop.Lenguitas</strong></p>
                <p><strong>Monto: ${data.precioTotal}</strong></p>
                <p><strong>Usa de referencia: P{data.id}</strong></p>
                <button id='copiar' class="btn btn-primary" type="button" onClick={(e) => {return copyPrev('Coop.Lenguitas', 'copiar', 'Copiado!', 'Error copiando Alias. Copialo a mano.');}}>Copiar Alias</button>
              </div>
            </div>
      
            <div class="col-md-6">
              <div class="h-100 p-4 bg-body-tertiary border rounded-3">
                <h4>Mercado Pago</h4>
                <p>Hace click en este boton e ingresa el monto de tu orden manualmente.</p>
                <p><strong>Monto: ${data.precioTotal}</strong></p>
                <p><strong>Usa de referencia: P{data.id}</strong></p>
                <a target="_blank" href="https://link.mercadopago.com.ar/lenguitas" class="btn btn-primary" type="button">Pagar Ahora</a>
              </div>
            </div>
          </div>
          {data.state === 'created' ? <div style={{marginTop: 10, marginBottom: 22}}>Estamos esperando tu pago. Tene en cuenta que si no pagas dentro de las 48hs tu pedido sera cancelado automaticamente.</div> : null}
        </div>
        :null}

        {data.state === 'created' ?
        <div className="col-12">
          <h3 style={{display: 'block'}} >¿Ya pagaste?</h3>
          <div style={{marginTop: 10}}>Espera a recibir nuestra confirmacion por mail para pasar a retirar tus remeras por la Coope. Estamos de Lunes a Viernes de 9:30 a 17:30 hs. dentro del edificio de primaria y secundaria en el 1er subsuelo.</div>
        </div> :null} 

         <div style={{marginTop: 22}} className="col-12">
          <h3 style={{display: 'block'}} >Ayudanos a difundir</h3>
          <div id="sharetext" style={{marginTop: 10}}>Ayudanos a difundir esta iniciativa! Comparti la web haciendo <span style={{cursor: 'pointer', textDecoration: 'underline', color: 'rgb(13 110 253)'}} onClick={(e) => share()}>click aqui</span>. Gracias!</div>
        </div>

      </div>
      <Toaster position="bottom-left" toastOptions={{duration: 5000, style: {background: '#e3e3e3'}}}/>
      <Footer/>
    </>
  );
}

export default MiOrden;