import React from 'react';
import {Container, NavDropdown} from 'react-bootstrap';
import config from '../config';
import { useAuthContext } from '../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faSchool } from '@fortawesome/free-solid-svg-icons'


function Footer({extraContent}) {
  const { isAuthenticated, userRoles } = useAuthContext();

  return (
    <>

    <div className="container">
  <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
    <div className="col-md-4 d-flex align-items-center">
      <a href="/" className="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1">
        <FontAwesomeIcon width="30" height="30" size="2x" icon={faSchool}/>
      </a>
      <span className="mb-3 mb-md-0 text-body-secondary">2024 Cooperadora Lenguitas</span>
    </div>

    <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
      <li className="ms-3"><a title="Seguinos en Instagram" target="_blank" className="text-body-secondary" href="https://www.instagram.com/cooplenguitas"><FontAwesomeIcon size="2x" icon={faInstagram}/></a></li>
    </ul>
  </footer>
</div>
    </>
  );
}

export default Footer;