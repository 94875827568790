import React, { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import { useAuthContext } from '../contexts/AuthContext';
import Page404 from './404';
import api from "../lib/request.js";
import config from "../config.js";
import { Navigate } from 'react-router-dom';
import Footer from '../components/Footer';
import HeaderNavbar from '../components/HeaderNavbar';
import toast, { Toaster } from 'react-hot-toast';

function ReposicionPage() {
  const { isAuthenticated, userRoles } = useAuthContext();
  const [statusCode, setStatusCode] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  async function fetchData() {
    try {
      setLoading(true);
      const response = await api.get(`/api/v1/orders`);
      setLoading(false);
      setData(response);
      setStatusCode(null);
    } catch (e) {
      setLoading(false);
      console.log(e);
      if(e.statusCode === 404){
        setStatusCode('404');
      }
      setData(null);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (statusCode === '404') {
    return (<Page404/>);
  }

  if (!data) return;

  function getGoBack() {
    return window.encodeURI(`/admin/ordenes`);
  }

  function printPedidos (orders) {
    const modelos = {};
    const totales = {};
    let total = 0;
    orders.map(o => {
      o.items.map(item => {
        if (item.nombre in totales) {
        } else {
          totales[item.nombre] = 0
        }

        if (item.cantidadBackorder && o.backorderVoid !== true) {
          if (!modelos[item.nombre]) {
            modelos[item.nombre] = {};
          }
          if (!modelos[item.nombre][item.talle]) {
            modelos[item.nombre][item.talle] = {
              count: 0,
              orders: []
            };
          }
          modelos[item.nombre][item.talle].count = modelos[item.nombre][item.talle].count + item.cantidadBackorder;
          modelos[item.nombre][item.talle].orders.push(o.id);
          totales[item.nombre] += item.cantidadBackorder;
          total += item.cantidadBackorder;
        }
      })
    })

    return (
      <>
      <h4 style={{marginBottom: 20}}>Total: {total}</h4>
      {Object.keys(modelos).map(modelo => {
      return (
        <>
        <h4>{modelo} ({totales[modelo]})</h4>
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <td>Talle</td>
              <td>Cantidad</td>
              <td>En ordenes</td>
            </tr>
          </thead>
          <tbody>

        {Object.keys(modelos[modelo]).map(talle => {
          return (<tr><td>{talle}</td><td>{modelos[modelo][talle].count}</td><td>{modelos[modelo][talle].orders.map(o => (<><a target="_blank" href={`${config.prefixPath}/admin/ordenes#orden${o}`}>#{o}</a> </>))}</td></tr>)
        })}

        </tbody>
        </table>
        <br/>
        </>
        )
    })}
    </>
      )
  }

  if (!isAuthenticated) return (<Navigate to={`${config.prefixPath}/login?goback=${getGoBack()}`}/>)

  return (isAuthenticated && userRoles.includes('admin') &&
    <>
    <HeaderNavbar/>
     <ol className="breadcrumb navi">
      <li className="breadcrumb-item"><a href={`${config.prefixPath}/admin`}>Admin</a></li>
      <li className="breadcrumb-item active" aria-current="page">Reposición</li>
    </ol>
    <h1>Reposición</h1>
   
     <div className="row">
      <div className="col-12">
      
      {printPedidos(data)}

      </div>
    </div>
    <Toaster position="bottom-left" toastOptions={{duration: 5000, style: {background: '#e3e3e3'}}}/>
    <Footer/>
    </>
  );
}

export default ReposicionPage;