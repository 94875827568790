import React, { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import { useAuthContext } from '../contexts/AuthContext';
import Page404 from './404';
import api from "../lib/request.js";
import config from "../config.js";
import { Navigate } from 'react-router-dom';
import Footer from '../components/Footer';
import EditableInput from '../components/EditableInput';
import HeaderNavbar from '../components/HeaderNavbar';
import toast, { Toaster } from 'react-hot-toast';


function StockPage() {
  const { isAuthenticated, userRoles } = useAuthContext();
  const [statusCode, setStatusCode] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totales, setTotales] = useState(null);

  async function fetchData() {
    try {
      setLoading(true);
      const response = await api.get(`/api/v1/remeras`);
      setLoading(false);
      setData(response);
      setTotales(contar(response))
      setStatusCode(null);
    } catch (e) {
      setLoading(false);
      console.log(e);
      if(e.statusCode === 404){
        setStatusCode('404');
      }
      setData(null);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  if (statusCode === '404') {
    return (<Page404/>);
  }

  function contar(remeras) {
    const modelos = {};
    const modelosBackorder = {};
    let total = 0;
    let totalBackorder = 0;
    remeras.map((modelo, index) => {
      if (modelo.nombre in modelos) {
      } else {
        modelos[modelo.nombre] = 0
      }
      Object.keys(modelo.talles).map(talle =>{
        modelos[modelo.nombre] += modelo.talles[talle];
        total+=modelo.talles[talle];
      })
    });

    remeras.map((modelo, index) => {
      if (modelo.nombre in modelosBackorder) {
      } else {
        modelosBackorder[modelo.nombre] = 0
      }
      Object.keys(modelo.backorderLimit).map(talle =>{
        modelosBackorder[modelo.nombre] += modelo.backorderLimit[talle];
        totalBackorder+=modelo.backorderLimit[talle];
      })
    });

    return {
      modelos,
      total,
      totalBackorder,
      modelosBackorder
    }
  }

  function safeParseInt(input) {
    const number = Number(input);
    if (isNaN(number) || !/^\d+$/.test(input)) {
        return null;
    } else {
        return number;
    }
  }

  function updateState (path, newValue) {
    const parts = path.split('.');
    if (parts.length !== 2) {
      alert('Ingresa solo numeros');
      return;
    }
    const productId = safeParseInt(parts[0]);
    const talle = parts[1];
    const newStock = safeParseInt(newValue);

    if (newStock === null) {
      alert('Ingresa solo numeros');
      return;
    }
    const requestData = {
      method: "put",
      url: `/api/v1/stock/${productId}`,
      data: {
        newValue: newStock,
        talle: talle
      }
    };
    setLoading(true);
    api(requestData).then(res => {
      setLoading(false);
      if (res.ok) {
        // ok!
        fetchData();
        toast.success("Stock actualizado con exito!");
      } else if (res.error) {
        alert(res.message);
        toast.error(res.message)
      } else {
        toast.error("Error actualizando stock");
      }
    }).catch (e => {
      setLoading(false);
      console.log(e);
      toast.error("Error actualizando stock");
    });
  }

  function updateStateBackorder (path, newValue) {
    const parts = path.split('.');
    if (parts.length !== 2) {
      alert('Ingresa solo numeros');
      return;
    }
    const productId = safeParseInt(parts[0]);
    const talle = parts[1];
    const newStock = safeParseInt(newValue);

    if (newStock === null) {
      alert('Ingresa solo numeros');
      return;
    }
    const requestData = {
      method: "put",
      url: `/api/v1/backorder/${productId}`,
      data: {
        newValue: newStock,
        talle: talle
      }
    };
    setLoading(true);
    api(requestData).then(res => {
      setLoading(false);
      if (res.ok) {
        // ok!
        fetchData();
        toast.success("Backorder actualizado con exito!");
      } else if (res.error) {
        alert(res.message);
        toast.error(res.message)
      } else {
        toast.error("Error actualizando Backorder");
      }
    }).catch (e => {
      setLoading(false);
      console.log(e);
      toast.error("Error actualizando Backorder");
    });
  }

  if (!data) return;

  function getGoBack() {
    return window.encodeURI(`/admin/ordenes`);
  }

  if (!isAuthenticated) return (<Navigate to={`${config.prefixPath}/login?goback=${getGoBack()}`}/>)

  return (isAuthenticated && userRoles.includes('admin') &&
    <>
    <HeaderNavbar/>
     <ol className="breadcrumb navi">
      <li className="breadcrumb-item"><a href={`${config.prefixPath}/admin`}>Admin</a></li>
      <li className="breadcrumb-item active" aria-current="page">Stock</li>
    </ol>
    <h1>Stock</h1>
     <h4 key={'h'} style={{marginBottom: 20}}>Total: {totales.total}+{totales.totalBackorder} = {totales.total+totales.totalBackorder}</h4>
     <div key={'r'} className="row">
      <div key={'r2'} className="col-12">
        
      {data.map((modelo, index) => {
        return (<>
        <h4 key={index}>{modelo.nombre} ({totales.modelos[modelo.nombre]}+{totales.modelosBackorder[modelo.nombre]}={totales.modelos[modelo.nombre]+totales.modelosBackorder[modelo.nombre]})  {loading ? <Spinner size="sm" animation="border" /> : null}</h4>
       
        <table key={`ta${modelo.id}`}className="table table-striped table-hover">
          <thead key={`th${modelo.id}`}>
            <tr>
              <td>Talle</td>
              <td>Cantidad</td>
              <td>Backorder Limit</td>
            </tr>
          </thead>
          <tbody>

        {Object.keys(modelo.talles).map(talle => {
          return (
          <tr key={`t${modelo.id}${talle}`}>
            <td>{talle}</td>
            <td><EditableInput number={true} editable={true} name='stock' editStyle={{width: 50}} value={modelo.talles[talle]} index={0} onValueChange={updateState} path={`${modelo.id}.${talle}`}/></td>
            <td><EditableInput number={true} editable={true} name='backorder' editStyle={{width: 50}} value={modelo.backorderLimit[talle]} index={0} onValueChange={updateStateBackorder} path={`${modelo.id}.${talle}`}/></td>
          </tr>)
        })}

        </tbody>
        </table>
        <br/>
        </>)
      })}

      </div>
    </div>
    <Toaster position="bottom-left" toastOptions={{duration: 5000, style: {background: '#e3e3e3'}}}/>
    <Footer/>
    </>
  );
}

export default StockPage;