import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

function EditableTextArea ({ name, value, index, onValueChange, path, contentStyle, editStyle, editable}) {
    
    const [edit, setEdit] = useState(false);
    const [text, setText] = useState(value);

    useEffect(() => {
      if (edit) {
        document.getElementById(`${name}-${index}`).focus();
      }
    }, [edit, index, name]);

    const handleContentClick = (index, content) => {
      if (!editable) return;
      setEdit(true);
      setText(content);
    };

    function handleChange(event) {
      setText(event.target.value);
    }

    const handleEditableBlur = (event, index) => {
      setEdit(false);
      setText(event.target.value);
      if (onValueChange) onValueChange(path, event.target.value);
    };

    return (
        ((edit && editable) ? (
          <textarea 
              id={`${name}-${index}`}
              key={`${name}-${index}`}
              onChange={handleChange} 
              onBlur={(e) => handleEditableBlur(e, index)}
              value={text}
              style={{...editStyle}}
            />
          ) : (
            <div style={{cursor: editable ? "context-menu" : "", ...contentStyle}} id={`editable-${index}`} onClick={() => handleContentClick(index, text)}>{(text ? (<><div style={{backgroundColor: '#fff698', padding: 4, borderRadius: 3, border: '1px solid #7d7635'}}><ReactMarkdown>{text}</ReactMarkdown></div></>) : (editable ? (<small style={{fontStyle: 'italic', textDecoration: 'underline'}}>Click para agregar nota</small>) : '[empty]'))}</div>
        ))
    );
  }

export default EditableTextArea;