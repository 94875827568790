import React, { useState, useEffect } from "react";
import toast, { Toaster } from 'react-hot-toast';
import {Modal, Container} from 'react-bootstrap';
import HeaderNavbar from '../components/HeaderNavbar';
import Footer from '../components/Footer';
import Spinner from 'react-bootstrap/Spinner';
import config from "../config";
import api from "../lib/request.js";
import { useAuthContext } from '../contexts/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

function ProjectsList() {
  const { isAuthenticated, userRoles } = useAuthContext();
  const [data, setData] = useState(null);
  const [orden, setOrden] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [suscripcion, setSuscripcion] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedFullImage, setSelectedFullImage] = useState('');
  const [previewModelText, setPreviewModelText] = useState('');

  async function fetchData() {
    try {
      const response = await api.get(`/api/v1/remeras`);
      setData(response);
    } catch (e) {
      console.log('e===', e);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);


  function updateOrder (talle , remera, cantidad) {
    console.log('====talle====', talle);
    console.log('====cantidad====', cantidad);
    if (cantidad === 0) return;
    if (config.allowBackOrder === 'false') {
      // check stock
      if (cantidad > remera.talles[talle]) {
        alert('No tenemos mas stock de este talle para este modelo!');
        return;
      }
    }

    if (cantidad > remera.talles[talle]) {
      const sePasa = cantidad - remera.talles[talle];
      if (sePasa > remera.backorderLimit[talle]){
        alert('Llegaste al limite de nuestro stock para este modelo y talle! No podemos reservarte mas que esto.');
      return;
      }
    }

    let newValue = Object.assign(orden);
    if (newValue.filter(nv => nv.id === remera.id)[0]) {
      newValue = orden.map(o => {
        if (o.id === remera.id) {
          if (!o.talles) o.talles = {};
          o.talles[talle] = cantidad;
        }
        return o;
      })
    } else {
      const val = {
        id: remera.id,
        nombre: remera.nombre,
        precio: remera.precio,
        talles: {}
      }
      val.talles[talle] = cantidad; 
      newValue.push(val)
    }
    setOrden([...newValue]);
  }

  function removeFromOrder (talle, remeraId) {
    let newValue = Object.assign(orden);
    if (newValue.filter(nv => nv.id === remeraId)[0]) {
      newValue = orden.map(o => {
        if (o.id === remeraId) {
          delete o.talles[talle];
        }
        return o;
      })
    }
    setOrden([...newValue]);
  }

  function backOrderText(inStock, backOrder, fechaBackOrder) {
    if (inStock === 0) {
      return `En este talle y modelo tenemos para entrega a partir del ${fechaBackOrder}`;
    } else {
      return `En este talle y modelo tenemos ${inStock} para entrega inmediata y el resto de este talle y modelo para entrega a partir del ${fechaBackOrder}`;
    }
    // Tenemos {count-backOrder} para entrega inmediata y {backOrder} para entrega a partir del {config.fechaBackOrder}
  }

  function BackOrderLeyend ({stock, count}) {
    let backOrder = 0;
    backOrder = stock - count;
    if (backOrder < 0) {
      backOrder = Math.abs(backOrder);
    } else {
      backOrder = 0;
    }
    // console.log('=====backOrder===', backOrder);
    let inStock = count-backOrder;
    return (
      backOrder > 0 ? <div style={{display: 'flex'}}><small style={{marginLeft: 19, fontStyle: 'italic'}}>{backOrderText(inStock, backOrder, config.fechaBackOrder)}</small></div> : (count > 0 ? (<div style={{display: 'flex'}}><small style={{marginLeft: 19, fontStyle: 'italic'}}>En este talle y modelo tenemos para entrega inmediata</small></div>) : null)
    );
  }

  function getBackorder ({stock, count}) {
    let backOrder = 0;
    backOrder = stock - count;
    if (backOrder < 0) {
      backOrder = Math.abs(backOrder);
    } else {
      backOrder = 0;
    }
    return {
      inmediata: count - backOrder,
      backOrder
    };
  }

  function getBonos () {
    let bonos = 0;
    let total = 0;
    orden.map((o, index) => {
      return Object.keys(o.talles).map((talle, talleIndex) => {
        bonos = bonos + o.talles[talle];
        total = total + (o.precio * o.talles[talle]);
      })
    })
    return {bonos, total};
  }

  function parseOrder (myOrder, remeras) {
    let items = [];
    orden.map((o, index) => {
      return Object.keys(o.talles).map((talle, talleIndex) => {
        const backData = getBackorder({stock: remeras.filter(r => r.id === o.id)[0].talles[talle], count: o.talles[talle]})
        items.push({
          id: o.id,
          nombre: o.nombre,
          precioUnitario: o.precio,
          talle: talle,
          cantidadTotal: o.talles[talle],
          cantidadEntregaInmediata: backData.inmediata,
          cantidadBackorder: backData.backOrder,
          precioTotal: (o.precio * o.talles[talle])
        });
      })
    });

    return items;
  }

  async function handleSubmit(event) {
    // Prevent form being submitted through HTML
    event.preventDefault();
    setLoading(true);

    if (!nombre.trim()) {
    alert('Ingresa tu nombre y apellido!')
    setLoading(false);
    return;
    }

    if (!email.trim()) {
      alert('Ingresa tu direccion de email!')
      setLoading(false);
      return;
    }

    if (!validateEmail(email.trim())) {
      alert('Ingresa una direccion de email valida!')
      setLoading(false);
      return;
    }

    const myOrder = parseOrder(orden, data);
    console.log(myOrder);

    const requestData = {
      method: 'post',
      url: '/api/v1/checkout',
      data: {
        "nombre": nombre.trim(),
        "email": email.trim(),
        "telefono": telefono.trim(),
        "suscripcion": suscripcion,
        "orden": myOrder
      }
    };

    try {
     const response = await api(requestData);
     // Update the state and close the modal
     setLoading(false);
     if (response.ok === true) {
       // Fetch data again
       document.getElementById('theform').reset();
       toast.success(response.message);
       document.location = `${config.prefixPath}/mi-orden/${response.order.slug}`;
     } else {
       if (response.error) {
         toast.error(response.message);
       }
     }
    } catch (error) {
      setLoading(false);
      toast.error(error.toString());
    }
   }

   function validateEmail(email) {
    // Define the regular expression for validating the email.
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Validate the email using the regular expression.
    return emailRegex.test(email);
  }

  function haveFinalStock(remera, talle) {
    if (config.allowBackOrder === 'false' && remera.talles[talle] <= 0) {
      return false;
    }
    if (config.allowBackOrder === 'true' && remera.talles[talle] <= 0 && remera.backorderLimit[talle] <= 0) {
      return false;
    }

    return true;
  }

  function TalleSelector ({remera}) {
    const myOrder = orden.filter(o => o.id === remera.id)[0] || {talles: {}};
    // console.log('====myOrder===', myOrder);
    return (
      <>
        {Object.keys(myOrder.talles).map((size, indexSize) => (
          <>
          <div style={{display: 'flex'}}><div className="talle">Talle {size}</div> <div className="box" onClick={(e) => {updateOrder(size, remera, (myOrder.talles[size]) - 1)}}> - </div> <div className="numero">{myOrder.talles[size]}</div> <div className="box" onClick={(e) => {updateOrder(size, remera, (myOrder.talles[size]) + 1)}}> + </div> <div onClick={(e) => removeFromOrder(size, remera.id)} className="borrar"><FontAwesomeIcon icon={faTrash}/></div></div>
          <BackOrderLeyend stock={remera.talles[size]} count={myOrder.talles[size]}/>
          </>
        ))}
        <select style={{marginLeft: 7, marginTop: 10, height: 36}} onChange={(e) => updateOrder(e.target.value, remera, 1)}> 
        <option selected="true" disabled>Selecciona un talle</option>
        <option disabled>Niños/Niñas</option>
        {Object.keys(remera.talles).map((talle, index) => (
          <>
          {talle === 'XS' && <option disabled>Adultos y Adolescentes</option>}
          <option disabled={!haveFinalStock(remera, talle)} value={talle}>{talle}{!haveFinalStock(remera, talle) ? ' (sin stock)':''}</option>
          </>
        ))}
        </select><div style={{marginLeft:10, cursor: 'pointer', textDecoration: 'underline', color: `rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1))`}} onClick={() => setModalShow(true)}>Ver tabla de talles</div>
      </>
    )
  }

  function CenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Tabla de talles de todos los modelos
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Container>
            <div className="row">
              <div className="col-12" style={{textAlign: "center"}}>
              <img width="100%" src="/uploads/tabla.png"/>
              </div>
            </div>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-outline-dark" onClick={props.onHide}>Cerrar</button>
        </Modal.Footer>
      </Modal>
    );
  }

  const handleFullImageClick = (image, modelo) => {
    setSelectedFullImage(image);
    setPreviewModelText(modelo);
    setShowImageModal(true);
  };

  const handleImageCloseModal = () => {
    setShowImageModal(false);
  };


  if (!data) return null;

  return (
    <>
    <CenteredModal show={modalShow} onHide={() => setModalShow(false)}/>
    <Modal
        show={showImageModal}
        onHide={handleImageCloseModal}
        dialogClassName="modal-fullscreen"
      >
        <Modal.Header closeButton>
          <Modal.Title>Modelo {previewModelText}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center align-items-center">
          <img
            src={selectedFullImage}
            alt="Full-view"
            className="img-fluid"
            style={{ maxHeight: '90vh' }}
            onClick={handleImageCloseModal}
          />
        </Modal.Body>
      </Modal> 

     <HeaderNavbar/>
      <h1 style={{display: 'block'}}>La Remera de la Coope 2024!</h1>
      <div style={{display: 'block'}} >Colabora con un bono contribución de <strong>$10.100</strong> y llevate una remera por cada bono.</div>
      <div>Las remeras son de manga corta unisex de algodón peinado 24/1 y estampado a 3 colores con serigrafía.</div>
      <div><strong><i>Lo recaudado sera utilizado para la puesta en valor de los baños.</i></strong></div>
      <span style={{display: 'block'}}></span>
      <div style={{height: 30}}></div>
      <div className="row">
        <div className="col-12">
        <h2 style={{display: 'block'}} >1. Elegi modelo, talle y cantidad</h2>
        {data.map((remera, index) => (
          <>
           <div className="card-body card mb-3 text-decoration-none bg-body-tertiary" key={`c-${index}`}>
             <div className="row">
              <div className="col-12">
                <div className="row">
                 <h3>{remera.nombre}</h3>
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-lg-4">
                  <img style={{cursor: 'pointer', border: '1px solid #c3c3c3'}} onClick={(e) => handleFullImageClick(e.target.src, remera.nombre)} width="100%" src={`/uploads/${remera.fotos[0]}`}/>
                </div>
                 <div className="col-1 col-lg-1 col-md-1">
                 {remera.fotos.map(f => {
                   return <img style={{cursor: 'pointer', border: '1px solid #c3c3c3', margin: 3}} onClick={(e) => handleFullImageClick(e.target.src, remera.nombre)} width="90px" src={`/uploads/${f}`}/>
                 })}
                
                 </div>
               <div style={{marginLeft:25}} className="col-12 col-lg-5 col-md-12">
                <TalleSelector remera={remera}/>
              </div>
            </div>
            </div>
           </div>
          </>
        ))}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <h2 style={{display: 'block'}} >2. Resumen de tu pedido</h2>
          <div className="card-body card mb-3 text-decoration-none bg-body-tertiary" key={`detalle`}>
          {getBonos().bonos === 0 ? <div>Aun no seleccionaste talles de ningun modelo.</div> : <>
            {orden.map((o, index) => {
              return Object.keys(o.talles).map((talle, talleIndex) => (
                <div><strong>{o.talles[talle]}x</strong> Modelo <strong>{o.nombre}</strong> en talle <strong>{talle}</strong><BackOrderLeyend stock={data.filter(r => r.id === o.id)[0].talles[talle]} count={o.talles[talle]}/></div>
              ))
            })}
            <div style={{fontSize: '1.5em'}}><strong>{getBonos().bonos}</strong> bono{getBonos().bonos === 1 ? '' :'s'} por un total de <strong>${Intl.NumberFormat("de-DE").format(getBonos().total)}.-</strong></div>
          </>}
          </div>
        </div>
        {getBonos().bonos > 0 ?
         <div className="col-12">
         <h2 style={{display: 'block'}} >3. Completa tus datos</h2>
          <div className="card-body card mb-3 text-decoration-none bg-body-tertiary" key={`detalle`}>
          <form id="theform">
                <div className="form-group" style={{marginTop: 15}}>
                  <label>Nombre y Apellido (*)</label>
                  <input style={{width:'23em'}} type="text" className="form-control" name="nombre" value={nombre} onChange={e => setNombre(e.target.value)} />
                </div>
                <div className="form-group" style={{marginTop: 15}}>
                  <label>Email (*)</label>
                  <input style={{width:'23em'}} type="email" className="form-control" name="email" value={email} onChange={e => setEmail(e.target.value)} />
                </div>
                <div className="form-group" style={{marginTop: 15}}>
                  <label>Numero de Telefono</label>
                  <input style={{width:'23em'}} type="telefono" className="form-control" name="telefono" value={telefono} onChange={e => setTelefono(e.target.value)} />
                </div>
                <input style={{marginTop: 15}} checked={suscripcion} onChange={e => setSuscripcion(e.target.checked)} type="checkbox"/>
                 <label onClick={e => setSuscripcion(!suscripcion)}  style={{marginLeft: 5}}>Acepto recibir novedades de la Coope</label>
                 <div style={{marginTop: 20}}>
                 {loading ? <Spinner animation="border" /> : <button type="button" class="btn btn-primary btn-lg" onClick={handleSubmit}>Realizar pedido y pagar</button>}
                 </div>
              </form>
          </div>
        </div>
        : null}

      </div>

      <Toaster position="bottom-left" toastOptions={{duration: 5000, style: {background: '#e3e3e3'}}}/>
      <Footer/>
    </>
  );
}

export default ProjectsList;