// import { toast } from 'react-hot-toast';
// https://sarathadhithya.medium.com/optimizing-api-requests-with-axios-interceptors-and-advanced-error-handling-3f69b1e05868
import Axios from 'axios';
import config from '../config';

const api = Axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(
  (response, req) => {
    //console.log('response', response)
    //console.log('req', req)
    if (response?.status === 403) {
      window.location.href=`${config.prefixPath}/login?externalError=forbiden`;
      return response.data
    }
    if (response?.status === 401) {
      window.location.href=`${config.prefixPath}/login?externalError=unauthorized`;
      return response.data
    }
    const data = response?.data;
    return data;
  },
  (error) => {
    //console.log(error.response.config.metadata)
    //console.log('error====')
    //console.log(error);
    //console.log('=/====error===')
    if (error?.response?.status === 403) {
      if (error?.response?.config?.metadata?.originalPath?.indexOf(`/api/v1/auth/login`) === 0 ) {
        return error.response.data;
      }
      window.location.href=`${config.prefixPath}/login?externalError=forbiden`;
      return error.response.data
    }
    if (error?.response?.status === 401) {
      if (error?.response?.config?.metadata?.originalPath?.indexOf(`/api/v1/auth/login`) === 0 ) {
        return error.response.data;
      }
      window.location.href=`${config.prefixPath}/login?externalError=unauthorized`;
      return error.response.data
    }

    const data = error.response.data;
    return Promise.reject(data);
  }
);

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  config.metadata = {originalPath: config.url};
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
