import React from "react";
import HeaderNavbar from '../components/HeaderNavbar';
import config from "../config";
import Footer from '../components/Footer';

function Page404() {

  return (
    <>
     <HeaderNavbar/>
      <ol className="breadcrumb">
        <li className="breadcrumb-item active"><a href={`${config.prefixPath}`}>Home</a></li>
      </ol>
      <h1 key="nav3">404</h1>
      <div className="row">
      <h2>Page not Found</h2>
      </div>
       <Footer/>
    </>
  );
}

export default Page404;