import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { AuthProvider } from './contexts/AuthContext';
import HomePage from './pages/Home';
import LoginPage from './pages/Login';
import config from './config';
import Stock from './pages/Stock';
import Reposicion from './pages/Reposicion';
import Remeras from './pages/Remeras';
import Ayuda from './pages/Ayuda';
import MiOrden from './pages/MiOrden';
import Orders from './pages/Orders';
import Page404 from './pages/404';

function App() {
  return (
    <Container>
    <AuthProvider>
    <Router>
      <Routes>
        <Route path='/' element={<Navigate to={`${config.prefixPath}`} />} />
        <Route path='/login' element={<Navigate to={`${config.prefixPath}/login`} />} />
        <Route path='/admin' element={<Navigate to={`${config.prefixPath}/admin`} />} />
        <Route path={config.prefixPath} element={<HomePage />} />
        <Route path={`${config.prefixPath}/login`} element={<LoginPage />} />
        <Route path={`${config.prefixPath}/remeras`} element={<Remeras />} />
        <Route path={`${config.prefixPath}/ayuda`} element={<Ayuda />} />
        <Route path={`${config.prefixPath}/mi-orden/:orderSlug`} element={<MiOrden />} />
        <Route path={`${config.prefixPath}/admin`} element={<Navigate to={`${config.prefixPath}/admin/ordenes`} />} />
        <Route path={`${config.prefixPath}/admin/stock`} element={<Stock />} />
        <Route path={`${config.prefixPath}/admin/reposicion`} element={<Reposicion/>} />
        <Route path={`${config.prefixPath}/admin/ordenes`} element={<Orders/>} />
        <Route path="*"element={<Page404/>}/>
      </Routes>
    </Router>
    </AuthProvider>
    </Container>
  );
}

export default App;
